import React, {Component} from 'react';
import firebase from 'firebase';

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Button from "components/CustomButtons/Button.js";

class FavoriteCompo extends Component {
    static getDerivedStateFromProps(nextProps, nextState) {
        const { uid, likes = [] } = nextState;
        let liked = likes.indexOf(uid) !== -1;
        console.log('Favorites compo -> getDerived', likes, likes.indexOf(uid), liked)
        return { likes, liked };
    }

    constructor(props) {
        super(props);
        this.state= { liked: false,  likes: (props.likes || []).map(ref => ref.id) };
        this.meGusta = this.meGusta.bind(this);
        console.log("postId", props.postId);
        
    }

    componentDidMount() {
        const { uid = null } = firebase.auth().currentUser || {};
        if (uid) this.setState({ uid });
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ uid: user.uid });
            }
        });
    }

    componentWillUnmount() {
        // cancelar subscripcion a auth
    }

    async meGusta(){
        const db = firebase.firestore();
        const { postId }= this.props;
        const { liked, likes = [], uid } = this.state;
        if (!liked) {
            console.log('FavoriteCompo -> meGusta', postId, likes);
            likes.push(uid);
            this.setState({ likes });
            await db.collection('posts').doc(postId).update({
                likes: firebase.firestore.FieldValue.arrayUnion(this.getProfileRef())
            });
           
        } else {
            console.log('FavoriteCompo -> meGusta no like', postId, likes);
            this.setState({ likes: likes.filter((currentUid) => currentUid !== uid) });
            await db.collection('posts').doc(postId).update({
                likes: firebase.firestore.FieldValue.arrayRemove(this.getProfileRef())
            });
        }
    }

    getProfileRef(){
        const db = firebase.firestore();
        const { uid = null } = firebase.auth().currentUser || {};
        return db.collection('profile').doc(uid);
    }

    render() {
        const { liked, likes, uid } = this.state;
        console.log('FavoriteCompo -> likes', likes);   
        console.log('FavoriteCompo -> uid', uid);
        return(
            <Button
                variant="contained"
                color="secundary"
                startIcon={liked ?  <FavoriteIcon /> : <FavoriteBorderIcon />}
                onClick = {this.meGusta}
                >
                Like
            </Button>
        )
    }
}
export default FavoriteCompo;