import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import  firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore"

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
// import Components from "views/Components/Components.js";
import Formulario from "views/Formulario/Formulario.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import HomePage from "views/HomePage/HomePage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import Administracion from "views/Administracion/Administracion.js";

var hist = createBrowserHistory();

const firebaseConfig = {
  apiKey: "AIzaSyBeC58C7P8gIcoG-ZCQ_RVna3y57dmY0_o",
  authDomain: "hola-3e36f.firebaseapp.com",
  databaseURL: "https://hola-3e36f-default-rtdb.firebaseio.com",
  projectId: "hola-3e36f",
  storageBucket: "hola-3e36f.appspot.com",
  messagingSenderId: "390511923902",
  appId: "1:390511923902:web:66d438ad66d6caffe844ef"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/formulario" component={Formulario} />
      <Route path="/administracion" component={Administracion} />
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/" component={HomePage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
