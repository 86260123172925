import firebase from 'firebase';
import React, {Component} from 'react';
import PostDetailAdmin from "views/Administracion/PostDetailAdmin";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class Saludar extends Component {
    constructor(props) {
        super(props);
        this.state = { posts: []};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

    componentDidMount(){
       this.loadPosts();
    }

  
    
    handleChange(event) {
        this.setState({nombre: event.target.value});
    }

    handleSubmit(event) {
        alert(this.state.nombre + ' ha sido guardado con exito');
        event.preventDefault();
        this.props.history.push(`./saludo/${this.state.nombre}`);
    }

    renderPostItem(post){
        const postData = post.data();
        console.log(postData);
        return (<PostDetailAdmin title={postData.title} fecha={postData.fecha.toDate()} subtitle={postData.subtitle} description={postData.description} image={postData.image} />);
    }

    async loadPosts(){
        const db = firebase.firestore();
        const { docs: posts } = await db.collection('posts').get();
        posts.sort((post1,post2) => post2.data().fecha.toMillis() - post1.data().fecha.toMillis());
        this.setState({ posts });
    }
    render(){
        console.log(this.state.posts );
        return(
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>  
                  <TableCell align="right">Titulo</TableCell>
                  <TableCell align="right">Fecha</TableCell>
                  <TableCell align="right">Subtitulo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.posts.map((postDoc) => {
                    const postData = postDoc.data();
                    console.log(postData.fecha);
                    
                    return <PostDetailAdmin title={postData.title} fecha={postData.fecha.toDate()} subtitle={postData.subtitle} />
    })}
              </TableBody>
            </Table>
          </TableContainer>
        )
    }

}
export default Saludar;