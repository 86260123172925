import React, {Component} from 'react';
import firebase from 'firebase';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Guardar from "@material-ui/icons/Bookmark";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import PerfilList from "components/PerfilList/PerfilList.js";
import SavePerfilList from "components/SavePerfilList/SavePerfilList.js"; 


import styles from "assets/jss/material-kit-react/views/profilePage.js";

import { withStyles } from '@material-ui/core/styles';

class Perfil extends Component {
  constructor(props) {
      super(props);
      this.state = { profile: null };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

  componentDidMount(){
     this.loadProfile();
     this.loadPosts();
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.loadProfile();
        this.loadPosts();
      }
    });
     
  }


  
  handleChange(event) {
      this.setState({nombre: event.target.value});
  }

  handleSubmit(event) {
      alert(this.state.nombre + ' ha sido guardado con exito');
      event.preventDefault();
      this.props.history.push(`./saludo/${this.state.nombre}`);
  }

  renderPostItem(post){
      const postData = post.data();
      console.log(postData);
      // return (<PostDetailAdmin title={postData.title} fecha={postData.fecha.toDate()} subtitle={postData.subtitle} description={postData.description} image={postData.image} />);
  }

  async loadProfile(){
      const db = firebase.firestore();
      const { uid = null } = firebase.auth().currentUser || {};
      if (!uid) return null;  
      const doc = await db.collection('profile').doc(uid).get();
      // posts.sort((post1,post2) => post2.data().fecha.toMillis() - post1.data().fecha.toMillis());
      this.setState({ profile: doc.data() });
  }

  async loadPosts(){
    const db = firebase.firestore();
    const { uid = null } = firebase.auth().currentUser || {};
    if (!uid) return null;
    const { docs: posts } = await db.collection('posts').where('autor', '==',  db.collection('profile').doc(uid)).get();
    // posts.sort((post1,post2) => post2.data().fecha.toMillis() - post1.data().fecha.toMillis());
    this.setState({ posts: posts.map(post => post.data()) });
}
  render(){
      console.log(this.state.profile, this.state.posts );
      const { classes, ...rest } = this.props;
      // const imageClasses = classNames(
      //   classes.imgRaised,
      //   classes.imgRoundedCircle,
      //   classes.imgFluid
      // );
      if (!this.state.profile) return null;
      
      // const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
      return (
        <div>
          <Header
            color="transparent"
            brand="Tappas-Perfil"

            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 200,
              color: "white"
            }}
            {...rest}
          />
          <Parallax small filter image={require("assets/img/rest.jpg")} />
          <div className={classNames(classes.main, classes.mainRaised)}>
            <div>
              <div className={classes.container}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <div >
                      <h2>Aqui encontrarás tus reseñas creadas y las que has guardado</h2>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                    <NavPills
                      alignCenter
                      color="primary"
                      tabs={[
                        {
                          tabButton: "Reseñas",
                          tabIcon: Edit,
                          tabContent: (
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={12} md={8}>
                                <PerfilList />
                              </GridItem>
                            </GridContainer>
                          )
                        },
                        {
                          tabButton: "Guardar",
                          tabIcon: Guardar,
                          tabContent: (
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={12} md={8}>
                               <SavePerfilList />
                              </GridItem>
                            </GridContainer>
                          )
                        }
                      ]}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ); 
  }
}
export default withStyles(styles)(Perfil);