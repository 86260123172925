import firebase from 'firebase';
import React, {Component} from 'react';
import PostDetail from "components/PostDetail/PostDetail.js";

import Box from '@material-ui/core/Box';
class Saludar extends Component {
    constructor(props) {
        super(props);
        this.state = { posts: [], uid: null };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderPostItem = this.renderPostItem.bind(this);
      }

    componentDidMount(){
       this.loadPosts();
       const { uid = null } = firebase.auth().currentUser || {};
        if (uid) this.setState({ uid });
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ uid: user.uid });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { uid: prevUid } = prevState;
        const { uid } = this.state;
        console.log('componentDidUpdate', prevUid, uid)
        if (uid !== prevUid && uid) {
            this.loadProfileData();
        }
    }
    
    async loadProfileData() {
        const { uid } = this.state;
        const db = firebase.firestore();
        const profileDoc = await db.collection('profile').doc(uid).get();
        const profileData = profileDoc.data();
        console.log('loadProfileData', profileDoc, profileData);
        this.setState({ profileData });
    }

    handleChange(event) {
        this.setState({nombre: event.target.value});
    }

    handleSubmit(event) {
        alert(this.state.nombre + ' ha sido guardado con exito');
        event.preventDefault();
        this.props.history.push(`./saludo/${this.state.nombre}`);
    }

    renderPostItem(post){
        const { profileData = {} } = this.state;
        const postData = post.data();
        console.log('renderPostItem', profileData, postData);
        return (<PostDetail title={postData.title} fecha={postData.fecha.toDate()} subtitle={postData.subtitle} description={postData.description} image={postData.image} likes={postData.likes} postId={post.ref.id} savedPosts={profileData.savedPosts}/>);
    }

    async loadPosts(){
        const db = firebase.firestore();
        const { docs: posts } = await db.collection('posts').get();
        posts.sort((post1,post2) => post2.data().fecha.toMillis() - post1.data().fecha.toMillis());
        this.setState({ posts });
    }
    render(){
        console.log(this.state.posts );
        return(
            <Box>
               {this.state.posts.map(this.renderPostItem)}
            </Box>
        )
    }

}
export default Saludar;