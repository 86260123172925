import firebase from 'firebase';
import React, {Component} from 'react';
import SavePerfil from "components/SavePerfil/SavePerfil.js";

import Box from '@material-ui/core/Box';
class SavePerfilList extends Component {
    constructor(props) {
        super(props);
        this.state = { posts: []};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

    
      componentDidMount() {
        const { uid = null } = firebase.auth().currentUser || {};
        if (uid) this.setState({ uid });
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ uid: user.uid });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { uid: prevUid } = prevState;
        const { uid } = this.state;
        console.log('componentDidUpdate', prevUid, uid)
        if (uid !== prevUid && uid) {
            this.loadProfileData();
        }
    }
    
    async loadProfileData() {
        const { uid } = this.state;
        const db = firebase.firestore();
        const profileDoc = await db.collection('profile').doc(uid).get();
        const profileData = profileDoc.data();
        console.log('loadProfileData', profileDoc, profileData);
        const posts = await this.loadPosts(profileData.postsSaved);
        this.setState({ posts });
    }
    
    handleChange(event) {
        this.setState({nombre: event.target.value});
    }

    handleSubmit(event) {
        alert(this.state.nombre + ' ha sido guardado con exito');
        event.preventDefault();
        this.props.history.push(`./saludo/${this.state.nombre}`);
    }

    renderPostItem(postData){
        console.log("SavePerfilList -> renderPostItem ",  postData);
        return (<SavePerfil title={postData.title} fecha={postData.fecha.toDate()} subtitle={postData.subtitle} description={postData.description} image={postData.image} />);
    }

    // async loadPosts(){
    //     const db = firebase.firestore();
    //     const { docs: posts } = await db.collection('posts').get();
    //     posts.sort((post1,post2) => post2.data().fecha.toMillis() - post1.data().fecha.toMillis());
    //     this.setState({ posts });
    // }

    async loadPost(postRef) {
        const postDoc = await postRef.get();
        console.log('PostData', postDoc.data());
        if (!postDoc.exists) return null;
        return postDoc.data();
    }

    async loadPosts(postsRefs =[]){
        const promises = [];
        postsRefs.map((postRef) => promises.push(this.loadPost(postRef)));
        const results = await Promise.all(promises);
        console.log('results', results);
        results.map((item) => console.log(item));
        return results;
    }
    render(){
        console.log('SavePerfilList -> posts', this.state.posts );
        return(
            <Box>
               {this.state.posts.map((post => this.renderPostItem(post)))}
            </Box>
        )
    }

}
export default SavePerfilList;

