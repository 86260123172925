import React, {Component} from 'react';
import firebase from 'firebase';

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Button from "components/CustomButtons/Button.js";

class BookCompo extends Component {
    static getDerivedStateFromProps(nextProps, nextState) {
        const {postId} = nextProps;
        const { profileData = null } = nextState;
        let saved = false;
        if (profileData) {
            const { postsSaved = []} = profileData;
            saved = postsSaved.map(ref => ref.id).indexOf(postId) !== -1;
            console.log('Favorites compo -> getDerived', saved, postsSaved.indexOf(postId));
        } else console.log('Favorites compo -> getDerived no profielData')
 
        return { saved };
    }

    constructor(props) {
        super(props);
        this.state= { saved: false };
        this.guardarPost = this.guardarPost.bind(this);
    }

    componentDidMount() {
        const { uid = null } = firebase.auth().currentUser || {};
        if (uid) this.setState({ uid });
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ uid: user.uid });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { uid: prevUid } = prevState;
        const { uid } = this.state;
        console.log('componentDidUpdate', prevUid, uid)
        if (uid !== prevUid && uid) {
            this.loadProfileData();
        }
    }
    
    async loadProfileData() {
        const { uid } = this.state;
        const db = firebase.firestore();
        const profileDoc = await db.collection('profile').doc(uid).get();
        if (!profileDoc.exists){
            await db.collection('profile').doc(uid).set({ createdAt: new Date() });
        }
        const profileData = profileDoc.data();
        console.log('loadProfileData', profileDoc, profileData);
        this.setState({ profileData });
    }

    componentWillUnmount() {
        // cancelar subscripcion a auth
    }

    async guardarPost(){
        const db = firebase.firestore();
        const { postId }= this.props;
        const { saved, uid } = this.state;
        if (!saved) {
            console.log('BookCompo -> meGusta', postId);
            await db.collection('profile').doc(uid).update({
                postsSaved: firebase.firestore.FieldValue.arrayUnion(db.collection('posts').doc(postId))
            });
           
        } else {
            console.log('BookCompo -> guardar no guardar', postId);
            await db.collection('profile').doc(uid).update({
                postsSaved: firebase.firestore.FieldValue.arrayRemove( db.collection('posts').doc(postId))
            });
        }
        this.loadProfileData();
    }

    getProfileRef(){
        const db = firebase.firestore();
        const { uid = null } = firebase.auth().currentUser || {};
        return db.collection('profile').doc(uid);
    }

    render() {
        const { saved, uid } = this.state;
        console.log('FavoriteCompo -> uid', uid);
        return(
            <Button
                variant="contained"
                color="secundary"       
                startIcon={saved ?  <BookmarkIcon /> : <BookmarkBorderIcon />}
                onClick = {this.guardarPost}
                >
                Guardar
            </Button>
        )
    }
}
export default BookCompo;