import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useHistory } from "react-router-dom";


import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/rest.jpg";

import  firebase from "firebase/app";

const useStyles = makeStyles(styles);

function loginGoogle() {

  var provider = new firebase.auth.GoogleAuthProvider();

  firebase.auth()
  .signInWithPopup(provider)
  .then((result) => {
    /** @type {firebase.auth.OAuthCredential} */
  }).catch((error) => {
  });
}

function loginUserPass (email, pass){
  firebase.auth().signInWithEmailAndPassword(email, pass)
  .then((userCredential) => {
   
  })
  .catch((error) => {
    var errorCode = error.code;
    console.error("error", error);

    if (errorCode === "auth/user-not-found"){
      firebase.auth().createUserWithEmailAndPassword(email, pass)
      .then((userCredential) => {
      })
      .catch((error) => {
      });
    }
    else{
      console.log("no");
    }
    
    // ..
  });
}



export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = React.useState("email");
  const [pass, setPass] = React.useState("pass");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const history = useHistory();
  const { ...rest } = props;

  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      console.log("tengo user");
      history.push('/');
    } else {
      console.log("no tengo user");
    }
  });

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Tappas - Inicio sesión"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Mediante Google o Email y Contraseña (min 6 carácteres)</h4>
                    <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e =>{ e.preventDefault(); loginGoogle();}}
                      >
                        <i className={"fab fa-google-plus-g"} />
                      </Button>
                    </div>
                  </CardHeader>
                  <p className={classes.divider}>Introduce tus datos</p>
                  <CardBody>
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        onChange: (event) => {console.log(event.target.value); setEmail(event.target.value)}
                      }}
                    />
                    <CustomInput
                      labelText="Contraseña..."
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        onChange: (event) => {console.log(event.target.value); setPass(event.target.value)},
                        autoComplete: "off"
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button simple color="primary" size="lg" onClick = {() => loginUserPass(email, pass)}>
                      Crear/Iniciar sesión
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
