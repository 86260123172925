import React from 'react';

import Typography from '@material-ui/core/Typography';


import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import moment from "moment/min/moment-with-locales";


export default function RecipeReviewCard({ title, fecha, subtitle}) {
  moment.locale('es');
  console.log('locale', moment.locale())
  console.log(fecha);
  return (
    <TableRow key={title}>
        <TableCell align="right"><Typography>{title}</Typography></TableCell>
        <TableCell align="right"><Typography>{moment(fecha).format('L')}</Typography></TableCell>
        <TableCell align="right"><Typography>{subtitle}</Typography></TableCell>
    </TableRow>
  );
}