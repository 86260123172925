import firebase from 'firebase';
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";


import { Redirect } from 'react-router-dom';




import styles from "assets/jss/material-kit-react/views/landingPage.js";
import styles2 from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

// Sections for this page
import PostsListAdmin from "views/Administracion/PostsListAdmin.js";
import { Typography } from '@material-ui/core';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

export default function LandingPage(props) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const { ...rest } = props;
  const [user = firebase.auth().currentUser || null, setUser] = React.useState("user");

  firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      console.log("tengo user", user);
      // window.history.back();
      const perfil = await firebase.firestore().collection('profile').doc(user.uid).get()
      setUser({ ...user, ...perfil.data()});

    } else {
      setUser('anonymous');
    }
  });

  if (!user) return null;
  else if (user === "anonymous") return <Redirect to="/login-page" />;
  else if (!user.admin) return <Typography>No tienes permiso para ver esta pagina</Typography>;

  return (
    <div>
      <Header
        color="white"
        routes={dashboardRoutes}
        brand="Tappas - Home"
        rightLinks={<HeaderLinks />}
        fixed
        
        {...rest}
      />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
        <div className={classes2.section}>
        <Grid container spacing={3}>
          <Grid item xs={8} sm={8}>
          <PostsListAdmin />
        </Grid>
        <Grid item xs={4} sm={4}>
        </Grid>
        </Grid>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
