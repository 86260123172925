import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase';

//calendario
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
//   KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";

//button
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

//spinner
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
    display: 'flex',
    flexWrap: 'wrap',
    },
    textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    },
    title: {
        color: 'black',
    }
});
  
 

class Saludar extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, image: null };
        this.handleChange = this.handleChange.bind(this);
        this.crearPost = this.crearPost.bind(this);
      }

    handleChange(field, value) {
      console.log(value)
      this.setState({ [field] : value});
      if (field === "image" && value !== null) {
        this.handleUpload(value);
      }
    }

    
    handleUpload(file) {
      // console.log(this.state.image);
      console.log('file', file);
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var uploadTask = storageRef.child('posts/' + file.name).put(file);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) =>{
          var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100
          this.setState({progress})
        },(error) =>{
          throw error
        },() =>{
          // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{

          uploadTask.snapshot.ref.getDownloadURL().then((url) =>{
            this.setState({
              downloadURL: url
            })
          })
        document.getElementById("file").value = null

      }
    ) 
    }

    crearPost(){
      console.log(this.state);
      const db = firebase.firestore();
      const { title, subtitle, fecha, description, downloadURL} = this.state;
      // Add a new document with a generated id.
      this.setState({ loading: true });
      const { uid = null } = firebase.auth().currentUser || {};
      console.log('crear Post uid', typeof uid, uid, db.doc(`profile/${uid}`).ref, db.doc(`profile/${uid}`));
      db.collection("posts").add({
        title,
        subtitle,
        fecha,
        description,
        autor: db.doc(`profile/${uid}`),
        image: downloadURL,
      })
      .then(async (docRef) => {
        console.log("Document written with ID: ", docRef.id);
        alert("Se ha publicado correctamente");
        this.setState({ title: "", subtitle: "", fecha: new Date(), description: ""});
        // await docRef.update({ autor: db.doc(`profile/${uid}`)});
        this.setState({ loading: false });
        
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this.setState({ loading: false });
      });
    }


    render(){
        console.log(this.state.posts );
        const { classes } = this.props;
        const isDisabled = !this.state.title || !this.state.subtitle || !this.state.fecha || !this.state.description || !this.state.downloadURL;
        const { loading } = this.state;
        console.log('disabled', isDisabled, loading, this.state.image, this.state.downloadURL);
        return(
            <div>
            <Typography variant="h3" component="h2" gutterBottom className={classes.title}>
                Formulario
            </Typography>
            <TextField
              id="filled-full-width"
              label="Introduce el titulo"
              style={{ margin: 8 }}
              placeholder="Texto"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              value={this.state.title}
              onChange={(event) => this.handleChange('title', event.target.value)}
            />
            <TextField
              id="filled-full-width"
              label="Introduce el subtitulo"
              style={{ margin: 8 }}
              placeholder="Texto"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              value={this.state.subtitle}
              onChange={(event) => this.handleChange('subtitle', event.target.value)}
            />
            <br/>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <Grid container justify="space-around">
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Selecciona la fecha"
                        format="dd/MM/yyyy"
                        value={this.state.fecha}
                        onChange={(value) => this.handleChange('fecha', value)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
            <br/>
            <TextField
                id="filled-multiline-static"
                label="Contenidos"
                fullWidth
                multiline
                rows={5}
                variant="filled"
                value={this.state.description}
                onChange={(event) => this.handleChange('description', event.target.value)}
            />
            <Typography variant="h6" component="h2" gutterBottom className={classes.title}>
                Introduce una imagen
            </Typography>
            <input id="file" type="file" onChange={(event) => this.handleChange('image',  event.target.files[0])} />
            <Button
              disabled = {isDisabled || loading}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={loading ?  <CircularProgress size={20} /> : <SaveIcon />}
              onClick = {this.crearPost}
            >
              Publicar
              </Button>
            </div>
        )
    }

}
export default withStyles(styles)(Saludar);