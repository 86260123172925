import firebase from 'firebase';
import React, {Component} from 'react';
import PostPerfil from "components/PostPerfil/PostPerfil.js";

import Box from '@material-ui/core/Box';
class PerfilList extends Component {
    constructor(props) {
        super(props);
        this.state = { posts: []};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

    componentDidMount(){
       this.loadPosts();
    }
    
    handleChange(event) {
        this.setState({nombre: event.target.value});
    }

    handleSubmit(event) {
        alert(this.state.nombre + ' ha sido guardado con exito');
        event.preventDefault();
        this.props.history.push(`./saludo/${this.state.nombre}`);
    }

    renderPostItem(post){
        const postData = post.data();
        console.log("postdata",  postData);
        return (<PostPerfil title={postData.title} fecha={postData.fecha.toDate()} subtitle={postData.subtitle} description={postData.description} image={postData.image} />);
    }
    
    async loadPosts(){
        const db = firebase.firestore();
        const { uid = null } = firebase.auth().currentUser || {};
        const { docs: posts } = await db.collection('posts').where('autor', '==',  db.collection('profile').doc(uid)).get();
        posts.sort((post1,post2) => post2.data().fecha.toMillis() - post1.data().fecha.toMillis());
        this.setState({ posts });
    }
    render(){
        console.log('PerfilList -> posts', this.state.posts );
        return(
            <Box>
               {this.state.posts.map((post => this.renderPostItem(post)))}
            </Box>
        )
    }

}
export default PerfilList;

